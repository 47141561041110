

import React, { useState } from 'react';
import { Tag } from 'antd';
import moment from "moment";
import { IoCopy, IoCopyOutline, IoEyeOutline } from "react-icons/io5";
import { formatNumberOnly } from '../../../utils/formatNumber';
import { Table } from '../../common/form_inputs/Table';
import { copyToClipboard } from '../../../utils/copyToClipboard';



const MandateStatusTable = ({rows, handleOpen}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copy, setCopy] = useState({
    icon: IoCopyOutline,
    state: false,
    id: null,
    text: "Copy url",
  });
  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;


  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const handleCopy = (val, index) => {
    console.log(val)
    copyToClipboard(`${baseUrl}/user/e_mandate/${val?.order_id}`)?.then((res) => {
      if(res.status) setCopy({ icon: IoCopy, state: true, text: "Copied", id: val?.id });
    })
};

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, row) => (
        <div className="flex flex-col">
          <p className="font-bold my-auto">{`${row?.first_name} ${row?.last_name}`}</p>
          <small className="text-gray-400" style={{ fontSize: '12px' }}>
            {moment(row?.createdAt).format('h:mm:ss a, D MMMM YYYY')}
          </small>
        </div>
      ),
    },
    {
      title: 'Mandate Ref',
      dataIndex: 'reference',
      render: (text, row)=>(
        <>
          {row?.order_id}
        </>
      )
    },
    {
      title: 'Total Repayable amount',
      dataIndex: 'totalRepayment',
      render: (text, row)=>(
        <>
          NGN {formatNumberOnly(parseFloat(row?.total_amount) - parseFloat(row?.total_paid))}
        </>
      )
    },
    {
      title: 'Total loan Repaid',
      dataIndex: 'totalRepayment',
      render: (text, row)=>(
        <>
          NGN {formatNumberOnly(parseFloat(row?.total_paid))}
          <div>
            <Tag color={row?.status==='active'?"success": row?.status==="reschedule approved"? "success": row?.status==="reschedule pending"? "purple": 'orange'} className='capitalize border-0'>{row?.status}</Tag>

          </div>
        </>
      )
    },
    // {
    //   title: 'Copy Link',
    //   dataIndex: 'status',
    //   render: (text, row)=>(
    //     <span
    //     className="flex gap-x-1 text-[#733534] cursor-pointer"
    //     onClick={()=>handleCopy(row)}
    //   >
    //     {
    //       copy?.id===row?.id && copy?.state ? (
    //         <IoCopy color="#733534" size={"3vh"} />
    //       ): <IoCopyOutline color="#733534" size={"3vh"} />
    //     }
    //     {copy?.id===row?.id && copy?.state ? "Copied" : "Copy url"}
    //   </span>
    //   )
    // },
    {
      title: '',
      dataIndex: 'action',
      render: (text, row)=>(
        <>
          <small className='cursor-pointer' onClick={()=>handleOpen(row)}>
            <IoEyeOutline size={"18px"}/>
  
          </small>
        </>
      )
    },
  ];



  const dataSource = rows?.map((data, i) => ({...data, key: data?.id+i}));


  




  return (
    <>
        <Table 
        // rowSelection={rowSelection} 
        columns={columns} dataSource={dataSource} scroll={{ x: 'max-content' }} pagination={false}
        striped={true}
        />
    
    </>
  );
};
export default MandateStatusTable;