import { Input, Select, Result } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDrawer } from "../../../hooks/zustand_hooks/useDrawer";
import { useUserPermission } from "../../../hooks/useUserPermission";
import UserTable from "../../../components/common/table/users_table/UserTable";
import { useGetUsers } from "../../../api/admin_mutation";
import Pagination from "../../../components/common/form_inputs/Pagination";
import { Spin } from "../../../components/common/form_inputs/Spin";

const { Search } = Input;

export default function Users() {
  // const { data, isLoading, isError } = useGetCustomers(1); //it will fetch the first page on initial


    const [currentData, setCurrentData] = useState([]);

  const {
    mutate: mutateGetUsers,
    data,
    isPending: isLoading,
    isError,
  } = useGetUsers(true);
  useEffect(() => {
    mutateGetUsers({
      page: 1,
    });
  }, []);

  const {
    user_permission,
    isError: permissionError,
    isLoading: permissionLoading,
  } = useUserPermission();


  const { openDrawer, updateDrawerTitle } = useDrawer();

  const handleOpenDrawer = (data) => {
    openDrawer("BUSINESS_DETAIL", { company: data });
    updateDrawerTitle(``);
  };

  const response = data?.data?.data;

    useEffect(() => {
    if (!isLoading && response?.data) {
      setCurrentData(response?.data);
    }
  }, [isLoading, response]);

  const tableData = useMemo(() => {
    return data?.data?.data?.data;
  }, [data?.data]);



  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const filterData = () => {
      if (searchValue?.trim()) {
        const value = searchValue?.toLowerCase();
        const updatedData = tableData?.filter((item) => {
          const matches = [item?.name?.toLowerCase()].some((field) =>
            field?.includes(value)
          );
          return matches;
        });

        setFilteredData(updatedData?.length ? updatedData : []);
      } else {
        // If search is cleared, show full data
        setFilteredData(tableData);
      }
    };

    filterData();
  }, [searchValue, tableData]);

  const tableRows = searchValue?.length ? filteredData : currentData;

  const handlePaginateChange = (page) => {
    mutateGetUsers({
      page,
    });
  };





  return (
    <>
      <main className="mt-3">
        <h3 className="font-[500] text-[18px] my-2 text-black">Business</h3>
        <div className="flex justify-between mb-3 gap-3 flex-wrap">
          <div>
            <Search
              placeholder="Search Name"
              onChange={(e) => setSearchValue(e.target.value)}
              style={{
                width: 200,
              }}
            />
          </div>
          {/* <div className="flex gap-x-2">
            <Select
              defaultValue="last 7 days"
              style={{
                width: 120,
              }}
              options={[
                {
                  value: "last 14 days",
                  label: "last 14 days",
                },
                {
                  value: "last 21 days",
                  label: "last 21 days",
                },
                {
                  value: "last 28 days",
                  label: "last 28 days",
                },
              ]}
            />
          </div> */}
        </div>

        {
          isLoading && !tableRows?.length ? (
              <div className="flex justify-center">
                <Spin />
              </div>
            ) :
          isError ? (
            <Result
              status="error"
              title="An error occurred"
              extra={null}
            />
          ) : permissionLoading ? (
            <div className="flex justify-center">
              <Spin />
            </div>
          ) : permissionError ? (
            <Result
              status="error"
              title="An error occurred"
              extra={null}
            />
          ) : user_permission ? (
            user_permission?.view_customers ? (
              <div className="relative">
                {isLoading && (
                  <div className="absolute top-[20vh] left-[50vh] flex justify-center z-10">
                    <Spin />
                  </div>
                )}
                <UserTable
                  rows={tableRows ?? []}
                  handleOpen={handleOpenDrawer}
                />
                <div className="mt-2">
                  <Pagination
                    current={response?.currentPage}
                    align={"end"}
                    total={response?.totalRecords}
                    onChange={handlePaginateChange}
                  />
                </div>
              </div>
            ) : (
              <div className="text-center my-10">
                <i className="text-lg">
                  You are not permitted to view customers
                </i>
              </div>
            )
          ) : (
            <Result
            status="error"
            title="An error occurred"
            extra={null}
          />
          )
        }
      </main>
    </>
  );
}
