import "./menuIconStyle.css";

export const MenuIcon=({clicked})=>{
    return (
        <>
        <div >
             <input type="checkbox" disabled checked={clicked??false} id="checkbox" />
            <label htmlFor="checkbox" className="toggle">
                <div className="bars" id="bar1"></div>
                <div className="bars" id="bar2"></div>
                <div className="bars" id="bar3"></div>
            </label>
        </div>
        </>
    )
}

export const RefreshIcon=({size})=>{
    return (
        <svg width={size} height={size} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.70004 15.4468C9.48004 15.4468 9.27337 15.3002 9.21337 15.0735C9.14671 14.8068 9.30004 14.5335 9.56671 14.4602C12.2734 13.7468 14.16 11.2935 14.16 8.4935C14.16 5.0935 11.3934 2.32683 7.99337 2.32683C5.10671 2.32683 3.21337 4.0135 2.32671 5.0335H4.28671C4.56004 5.0335 4.78671 5.26016 4.78671 5.5335C4.78671 5.80683 4.57337 6.04016 4.29337 6.04016H1.34004C1.29337 6.04016 1.24671 6.0335 1.20004 6.02016C1.14004 6.00016 1.08671 5.9735 1.04004 5.94016C0.980041 5.90016 0.933374 5.84683 0.900041 5.78683C0.866707 5.72016 0.840041 5.6535 0.833374 5.58016C0.833374 5.56016 0.833374 5.54683 0.833374 5.52683V2.50016C0.833374 2.22683 1.06004 2.00016 1.33337 2.00016C1.60671 2.00016 1.83337 2.22683 1.83337 2.50016V4.0935C2.92004 2.92683 4.96671 1.3335 8.00004 1.3335C11.9534 1.3335 15.1667 4.54683 15.1667 8.50016C15.1667 11.7535 12.9734 14.6068 9.82671 15.4335C9.78671 15.4402 9.74004 15.4468 9.70004 15.4468Z" fill="#ACA9BB"/>
        <path opacity="0.4" d="M7.52671 15.6533C7.51337 15.6533 7.49337 15.6533 7.48004 15.6467C6.76671 15.6 6.06671 15.44 5.40004 15.18C5.20671 15.1067 5.08004 14.9133 5.08004 14.7067C5.08004 14.6467 5.09337 14.5867 5.11337 14.5333C5.21337 14.28 5.50671 14.1467 5.76004 14.2467C6.33337 14.4733 6.94004 14.6067 7.55337 14.6533H7.56004C7.82671 14.6667 8.02671 14.8933 8.02671 15.16C8.02671 15.1667 8.02671 15.18 8.02671 15.1867C8.00671 15.4467 7.78671 15.6533 7.52671 15.6533ZM3.85337 14.22C3.74004 14.22 3.63337 14.1867 3.54004 14.1133C2.98004 13.6667 2.49337 13.14 2.08671 12.5467C2.02671 12.46 1.99337 12.3667 1.99337 12.2667C1.99337 12.1 2.07337 11.9467 2.21337 11.8533C2.43337 11.7 2.76004 11.76 2.90671 11.98C3.26004 12.4933 3.68004 12.9467 4.16671 13.3267C4.28004 13.42 4.35337 13.56 4.35337 13.7133C4.35337 13.8267 4.32004 13.94 4.24671 14.0333C4.15337 14.1533 4.00671 14.22 3.85337 14.22ZM1.62671 10.9667C1.40671 10.9667 1.21337 10.8267 1.15337 10.62C0.940041 9.93333 0.833374 9.22 0.833374 8.5C0.833374 8.22667 1.06004 8 1.33337 8C1.60671 8 1.83337 8.22667 1.83337 8.5C1.83337 9.12 1.92671 9.73333 2.10671 10.32C2.12004 10.3667 2.12671 10.42 2.12671 10.4733C2.12671 10.6933 1.98671 10.88 1.78004 10.9467C1.72671 10.96 1.68004 10.9667 1.62671 10.9667Z" fill="#ACA9BB"/>
        <path d="M8.00004 11.1668C9.4728 11.1668 10.6667 9.97292 10.6667 8.50016C10.6667 7.0274 9.4728 5.8335 8.00004 5.8335C6.52728 5.8335 5.33337 7.0274 5.33337 8.50016C5.33337 9.97292 6.52728 11.1668 8.00004 11.1668Z" fill="#ACA9BB"/>
        </svg>
        
        
    )
}

export const CollectionIcon=({size})=>{
    return (
        <svg width={size} height={size} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 6.83667H6.55V4.5H7.1L8.0405 6.83667H9.3V4.5H9.85V6.83667H10.4V7.61556H9.85V8.39444H10.4V9.17333H9.85V11.51H9.3L8.35675 9.17333H7.1V11.51H6.55V9.17333H6V8.39444H6.55V7.61556H6V6.83667ZM7.1 6.83667H7.41075L7.1 6.06946V6.83667ZM7.1 7.61556V8.39444H8.0405L7.727 7.61556H7.1ZM9.3 9.95222V9.17333H8.98375L9.3 9.95222ZM8.354 7.61556L8.67025 8.39444H9.3V7.61556H8.354Z" fill="#ACA9BB"/>
        <path opacity="0.4" d="M11.3334 5.66683C11.2067 5.66683 11.08 5.62016 10.98 5.52016C10.7867 5.32683 10.7867 5.00683 10.98 4.8135L12.3467 3.44683C11.1734 2.44683 9.66004 1.8335 8.00004 1.8335C4.32004 1.8335 1.33337 4.82016 1.33337 8.50016C1.33337 12.1802 4.32004 15.1668 8.00004 15.1668C11.68 15.1668 14.6667 12.1802 14.6667 8.50016C14.6667 6.84016 14.0534 5.32683 13.0534 4.1535L11.6867 5.52016C11.5867 5.62016 11.46 5.66683 11.3334 5.66683Z" fill="#ACA9BB"/>
        <path d="M15.1266 1.63999C15.0733 1.51999 14.98 1.42 14.8533 1.36666C14.7933 1.34 14.7266 1.32666 14.66 1.32666H11.9933C11.72 1.32666 11.4933 1.55333 11.4933 1.82666C11.4933 2.09999 11.72 2.32666 11.9933 2.32666H13.4533L12.34 3.44C12.5933 3.66 12.8266 3.89333 13.0466 4.14666L14.16 3.03333V4.49999C14.16 4.77333 14.3866 4.99999 14.66 4.99999C14.9333 4.99999 15.16 4.77333 15.16 4.49999V1.83333C15.1666 1.76666 15.1533 1.70666 15.1266 1.63999Z" fill="#ACA9BB"/>
        </svg>
    )
}
export const EnvelopeIcon=({size})=>{
    return (
        <svg width={size} height={size} viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.999674 1.16667V0.75C0.889168 0.75 0.783187 0.793899 0.705047 0.872039C0.626907 0.950179 0.583008 1.05616 0.583008 1.16667H0.999674ZM15.9997 1.16667H16.4163C16.4163 1.05616 16.3724 0.950179 16.2943 0.872039C16.2162 0.793899 16.1102 0.75 15.9997 0.75V1.16667ZM0.999674 1.58333H15.9997V0.75H0.999674V1.58333ZM15.583 1.16667V11.1667H16.4163V1.16667H15.583ZM14.333 12.4167H2.66634V13.25H14.333V12.4167ZM1.41634 11.1667V1.16667H0.583008V11.1667H1.41634ZM2.66634 12.4167C2.33482 12.4167 2.01688 12.285 1.78246 12.0505C1.54804 11.8161 1.41634 11.4982 1.41634 11.1667H0.583008C0.583008 11.7192 0.802501 12.2491 1.1932 12.6398C1.5839 13.0305 2.11381 13.25 2.66634 13.25V12.4167ZM15.583 11.1667C15.583 11.4982 15.4513 11.8161 15.2169 12.0505C14.9825 12.285 14.6645 12.4167 14.333 12.4167V13.25C14.8855 13.25 15.4154 13.0305 15.8061 12.6398C16.1968 12.2491 16.4163 11.7192 16.4163 11.1667H15.583Z" fill="#733534"/>
        <path d="M1 1.1665L8.5 8.6665L16 1.1665" stroke="#733534" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        
    )
}
export const WhatsAppIcon=({size})=>{
    return (
       
        <svg width={size} height={size} viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.50006 1.0415C4.54797 1.04692 0.537558 5.06567 0.542974 10.0178C0.544241 11.5205 0.923518 12.9988 1.64589 14.3165L0.958391 18.5415L5.18339 17.854C9.52089 20.2332 14.9667 18.6461 17.3459 14.3082C19.7251 9.97025 18.1384 4.52525 13.8005 2.14567C12.4825 1.42266 11.0033 1.04294 9.50006 1.0415ZM5.02089 5.11234H7.46256C7.57306 5.11234 7.67904 5.15624 7.75718 5.23438C7.83533 5.31252 7.87922 5.4185 7.87922 5.529C7.86556 6.08467 7.95892 6.63778 8.15422 7.15817C8.23833 7.33237 8.25682 7.53105 8.20631 7.71779C8.15581 7.90452 8.03968 8.06679 7.87922 8.17484L7.02506 9.00817C7.40379 9.74777 7.899 10.4217 8.49172 11.004C9.06936 11.6064 9.7405 12.1115 10.4792 12.4998L11.3126 11.6457C11.7292 11.229 11.9209 11.229 12.3292 11.3707C12.8496 11.566 13.4027 11.6593 13.9584 11.6457C14.0673 11.6507 14.1703 11.6963 14.2474 11.7733C14.3245 11.8504 14.37 11.9535 14.3751 12.0623V14.504C14.37 14.6129 14.3245 14.7159 14.2474 14.793C14.1703 14.8701 14.0673 14.9156 13.9584 14.9207C11.5074 14.8139 9.18408 13.7978 7.44172 12.0707C5.71188 10.3302 4.69532 8.00574 4.59172 5.554C4.59272 5.43932 4.63787 5.32942 4.7178 5.24717C4.79772 5.16491 4.90628 5.11662 5.02089 5.11234Z" stroke="#733534" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    )
}
export const SmsIcon=({size})=>{
    return (
        <svg width={size} height={size} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.83333 7.8725C5.02278 7.8725 5.18778 7.80222 5.32833 7.66167C5.46889 7.52111 5.53917 7.35611 5.53917 7.16667C5.53917 6.97722 5.46889 6.81222 5.32833 6.67167C5.18778 6.53111 5.02278 6.46083 4.83333 6.46083C4.64389 6.46083 4.47889 6.53111 4.33833 6.67167C4.19778 6.81222 4.1275 6.97722 4.1275 7.16667C4.1275 7.35611 4.19778 7.52111 4.33833 7.66167C4.47889 7.80222 4.64389 7.8725 4.83333 7.8725ZM8.5 7.8725C8.68944 7.8725 8.85444 7.80222 8.995 7.66167C9.13556 7.52111 9.20583 7.35611 9.20583 7.16667C9.20583 6.97722 9.13556 6.81222 8.995 6.67167C8.85444 6.53111 8.68944 6.46083 8.5 6.46083C8.31056 6.46083 8.14556 6.53111 8.005 6.67167C7.86444 6.81222 7.79417 6.97722 7.79417 7.16667C7.79417 7.35611 7.86444 7.52111 8.005 7.66167C8.14556 7.80222 8.31056 7.8725 8.5 7.8725ZM12.1667 7.8725C12.3561 7.8725 12.5211 7.80222 12.6617 7.66167C12.8022 7.52111 12.8725 7.35611 12.8725 7.16667C12.8725 6.97722 12.8022 6.81222 12.6617 6.67167C12.5211 6.53111 12.3561 6.46083 12.1667 6.46083C11.9772 6.46083 11.8122 6.53111 11.6717 6.67167C11.5311 6.81222 11.4608 6.97722 11.4608 7.16667C11.4608 7.35611 11.5311 7.52111 11.6717 7.66167C11.8122 7.80222 11.9772 7.8725 12.1667 7.8725ZM0.25 16.403V2.23133C0.25 1.80906 0.391472 1.45675 0.674417 1.17442C0.957361 0.892083 1.30936 0.750611 1.73042 0.75H15.2696C15.6913 0.75 16.0433 0.891472 16.3256 1.17442C16.6079 1.45736 16.7494 1.80967 16.75 2.23133V12.1029C16.75 12.5246 16.6085 12.8769 16.3256 13.1598C16.0426 13.4428 15.6906 13.5839 15.2696 13.5833H3.07058L0.25 16.403ZM2.67917 12.6667H15.2696C15.4101 12.6667 15.5394 12.608 15.6573 12.4907C15.7753 12.3733 15.8339 12.2441 15.8333 12.1029V2.23042C15.8333 2.08986 15.7747 1.96061 15.6573 1.84267C15.54 1.72472 15.4108 1.66606 15.2696 1.66667H1.73042C1.58986 1.66667 1.46061 1.72533 1.34267 1.84267C1.22472 1.96 1.16606 2.08925 1.16667 2.23042V14.1746L2.67917 12.6667Z" fill="#733534"/>
        </svg>
        
        
    )
}

export const SettingIcon=({size})=>{
    return (
        <svg width={size} height={size} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M1.33337 9.08679V7.91345C1.33337 7.22012 1.90004 6.64679 2.60004 6.64679C3.80671 6.64679 4.30004 5.79345 3.69337 4.74679C3.34671 4.14679 3.55337 3.36679 4.16004 3.02012L5.31337 2.36012C5.84004 2.04679 6.52004 2.23345 6.83337 2.76012L6.90671 2.88679C7.50671 3.93345 8.49337 3.93345 9.10004 2.88679L9.17337 2.76012C9.48671 2.23345 10.1667 2.04679 10.6934 2.36012L11.8467 3.02012C12.4534 3.36679 12.66 4.14679 12.3134 4.74679C11.7067 5.79345 12.2 6.64679 13.4067 6.64679C14.1 6.64679 14.6734 7.21345 14.6734 7.91345V9.08679C14.6734 9.78012 14.1067 10.3535 13.4067 10.3535C12.2 10.3535 11.7067 11.2068 12.3134 12.2535C12.66 12.8601 12.4534 13.6335 11.8467 13.9801L10.6934 14.6401C10.1667 14.9535 9.48671 14.7668 9.17337 14.2401L9.10004 14.1135C8.50004 13.0668 7.51337 13.0668 6.90671 14.1135L6.83337 14.2401C6.52004 14.7668 5.84004 14.9535 5.31337 14.6401L4.16004 13.9801C3.55337 13.6335 3.34671 12.8535 3.69337 12.2535C4.30004 11.2068 3.80671 10.3535 2.60004 10.3535C1.90004 10.3535 1.33337 9.78012 1.33337 9.08679Z" fill="white"/>
        <path d="M8.00004 10.6668C9.19666 10.6668 10.1667 9.69678 10.1667 8.50016C10.1667 7.30355 9.19666 6.3335 8.00004 6.3335C6.80342 6.3335 5.83337 7.30355 5.83337 8.50016C5.83337 9.69678 6.80342 10.6668 8.00004 10.6668Z" fill="white"/>
        </svg>
        
        
    )
}


export const CheckMarkIcon=()=>{
    return(
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="100" height="100" rx="50" fill="#45B36B"/>
<path d="M66.667 39.5835L45.8337 60.4168L35.417 50.0002" stroke="white" stroke-width="5.20833" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    )
}