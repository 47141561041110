

import React, { useState } from 'react';
import { Table } from "../../form_inputs/Table";
import moment from "moment";
import { IoEyeOutline } from "react-icons/io5";



const CustomerListTable = ({rows, handleOpen}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;







  const columns = [
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      render: (text, row) => (
          <p className="">{moment(text).format("DD. MMM. YYYY")}</p>
       
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, row) => (
          <p className="">{`${row?.first_name} ${row?.last_name}`}</p>
    
      ),
    },
    {
      title: 'Customer ID',
      dataIndex: 'customer_id',
      render: (text, row) => (
          <p className="">{text}</p>
    
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text, row)=>(
        <>
          {text}
        </>
      )
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      render: (text, row)=>(
        <>
          {text}
        </>
      )
    },
    {
      title: '',
      dataIndex: 'action',
      render: (text, row)=>(
        <>
          <small className='cursor-pointer' onClick={()=>handleOpen(row)}>
            <IoEyeOutline size={"18px"}/>
  
          </small>
        </>
      )
    },
  ];


  const dataSource = rows?.map((data, i) => ({...data, key: data?.id+i}))?.reverse();





  return (
    <>
        <Table rowSelection={rowSelection} columns={columns} dataSource={dataSource} scroll={{ x: 'max-content' }} striped={true}/>
    
    </>
  );
};
export default CustomerListTable;