import { useState } from "react";
import {
  useGetMandateSchedule,
  useGetTimelinesWithID,
  useGetUserById,
} from "../../../api/getHooks";
import { IoEyeOutline } from "react-icons/io5";
import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Result } from "antd";
import { useDrawer } from "../../../hooks/zustand_hooks/useDrawer";
import useCustomMessage from "../../../hooks/zustand_hooks/useCustomMessage";
import { useUserPermission } from "../../../hooks/useUserPermission";
import { formatNumberOnly } from "../../../utils/formatNumber";
import { formatDate } from "../../../utils/formatDate";
import { Spin } from "../../common/form_inputs/Spin";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";

export default function TransactionDetail() {
  const [viewLoan, setViewLoan] = useState({
    state: false,
    icon: MdOutlineKeyboardArrowUp,
  });
  const {
    data: { transaction},
  } = useDrawer();

  const handleViewLoan = () => {
    setViewLoan({
      state: !viewLoan?.state,
      icon: viewLoan?.state ? MdOutlineKeyboardArrowDown : MdOutlineKeyboardArrowUp,
    });
  };

  const { contextHolder } = useCustomMessage();
 
  return (
    <>
      <div className="w-full max-w- mx-auto px-1 py-4 rounded-lg bg-white shadow-sm">
        <div className="flex items-center justify-between flex-wrap gap-y-2 mb-3">
          <h4 className="font-[600] text-[16px] text-center mb-2">
            Trannsaction Ref: {transaction?.reference}
          </h4>
          <div className={`capitalize flex gap-x-1 items-center max-w-full ${transaction?.status==="pending"? "bg-yellow-100 text-yellow-900" : (transaction?.status==="successful" || transaction?.status==="success")? "bg-green-100 text-green-900": "bg-red-100 text-red-500" } rounded-full px-2 text-center uppercase font-[500]`}>
            <p className={`h-2 w-2 rounded-full ${transaction?.status==="pending"? "bg-yellow-700" : (transaction?.status==="successful" || transaction?.status==="success")? "bg-green-700": "bg-red-700"}`}></p>
            <i>Transaction {transaction?.status}</i></div>
        </div>

        <div className="flex flex-col gap-y-1">
          <div className="grid grid-cols-2 lg:gap-x-8 gap-x-4 gap-y-1 mt-2">
            <p className="font-[500] text-[14px]">
              Customer Name:{" "}
              <span className="font-[300] capitalize">
                {transaction?.schedule?.mandate?.first_name}{" "}
                {transaction?.schedule?.mandate?.last_name}
              </span>
            </p>
            <p className="font-[500] text-[14px]">
              Customer ID: <span className="font-[300]">{}</span>
            </p>
            <p className="font-[500] text-[14px]">
              Amount Debited:{" "}
              <span className="font-[300]">
                NGN {formatNumberOnly(transaction?.amount)}
              </span>
            </p>
            <p className="font-[500] text-[14px]">
              Mandate Ref.: <span className="font-[300]">{""}</span>
            </p>
            <p className="font-[500] text-[14px]">
              Date Debited:{" "}
              <span className="font-[300]">
                {moment(transaction?.createdAt).format("lll")}
              </span>
            </p>
            <p className="font-[500] text-[14px]">
              Loan Schedule for:{" "}
              <span className="font-[300]">
                {moment(transaction?.schedule?.start_date).format("lll")}
              </span>
            </p>
          </div>
        </div>
        <div className="mt-5">
          <Button type="text" onClick={handleViewLoan}>
            View Loan Schedule <viewLoan.icon size={20} />
          </Button>
        </div>
        {viewLoan?.state ? (
          <div className="mt-3">
            <>
              {
                <>
                  <div className="flex flex-col gap-y-1 mt-4">
                    <div className="grid grid-cols-2 lg:gap-x-8 gap-x-4 gap-y-1 mt-2">
                      <p className="font-[500] text-[14px]">
                        Due Date:{" "}
                        <span className="font-[300] capitalize">
                          {moment(transaction?.schedule?.start_date).format(
                            "lll"
                          )}
                        </span>
                      </p>
                      <p className="font-[500] text-[14px]">
                        Schedule Status:{" "}
                        <span
                          className={`capitalize max-w-full ${
                            transaction?.schedule?.status === "not debit"
                              ? "bg-red-100 text-red-900"
                              : transaction?.schedule?.status === "part debited"
                              ? "bg-yellow-100 text-yellow-900"
                              : transaction?.schedule?.status ===
                                "fully debited"
                              ? "bg-green-100 text-green-900"
                              : transaction?.schedule?.status === "inactive"
                              ? "bg-yellow-100 text-yellow-900"
                              : transaction?.schedule?.status ===
                                "reschedule pending"
                              ? "bg-purple-100 text-purple-900"
                              : transaction?.schedule?.status ===
                                "reschedule pending"
                              ? "bg-purple-100 text-purple-900"
                              : transaction?.schedule?.status ===
                                "reschedule declined"
                              ? "bg-red-100 text-red-900"
                              : transaction?.schedule?.status ===
                                "reschedule approved"
                              ? "bg-green-100 text-green-900"
                              : transaction?.schedule?.status === "active" &&
                                "bg-green-100 text-green-900"
                          } px-2 rounded text-center`}
                        >
                          <i>
                            {transaction?.schedule?.status === "active"
                              ? "Not Due"
                              : transaction?.schedule?.status}
                          </i>
                        </span>
                      </p>
                      <p className="font-[500] text-[14px]">
                        Principal Amount:{" "}
                        <span className="font-[300]">
                          NGN {formatNumberOnly(transaction?.amount ?? 0)}
                        </span>
                      </p>
                      <p className="font-[500] text-[14px]">
                        Total Amount Due:{" "}
                        <span className="font-[300]">
                          NGN{" "}
                          {formatNumberOnly(transaction?.total_amount_due ?? 0)}
                        </span>
                      </p>
                      <p className="font-[500] text-[14px]">
                        Interest Payment:{" "}
                        <span className="font-[300]">
                          NGN{" "}
                          {formatNumberOnly(
                            transaction?.schedule?.interest ?? 0
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                  {/* <Table
                        header_bg={true}
                        pagination={false}
                        onRow={(row) => {
                          return {
                            onClick: () => handleOpenDrawer(row),
                          };
                        }}
                        rowSelection={rowSelection}
                        columns={ columns }
                        dataSource={dataSource}
                        size="small"
                        className={"w-full"}
                        rowClassName="cursor-pointer hover:bg-gray-100"
                      /> */}
                </>
              }
            </>
          </div>
        ) : null}
      </div>
      {contextHolder}
    </>
  );
}
