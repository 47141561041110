import React from 'react'
import CustomButton from '../../../common/CustomButton'
import { GoPlus } from "react-icons/go";
import TeamTable from '../../../common/table/team_table/TeamTable';
import { useDrawer } from '../../../../hooks/zustand_hooks/useDrawer';
import { useGetStaff } from '../../../../api/getHooks';
import { useUserPermission } from '../../../../hooks/useUserPermission';
import { Result } from 'antd';
import { Spin } from '../../../common/form_inputs/Spin';

export const TeamsView = () => {

  //external hooks
  const { openDrawer, updateDrawerTitle } = useDrawer()

  const { data: getStaff, isLoading, isError } = useGetStaff() //it will fetch the first page on initial

  const { user_permission } = useUserPermission()


  //

  const staffs = getStaff?.data?.data



 const handleInviteMember=()=>{
    openDrawer("INVITE_MEMBER", {})
      updateDrawerTitle("Invite Member")
  }



 const handleOpenUpdate=(staff_detail)=>{
    openDrawer("INVITE_MEMBER", {staff_detail})
      updateDrawerTitle("Update Staff")
  }


  const handleAction=(state, data)=>{
    if(state==='edit'){
      handleOpenUpdate(data)
    }
    else{
      console.log("delete")
    }
  }





  return (
    <>
        <main>
            <div className="flex justify-between mx-4">
                <p className="text-[18px] font-[500]">Team</p>
                <div className='flex gap-x-3 items-center'>
                <p className="text-[14px] font-[300]">Total members</p>
                <p className='flex justify-center items-center h-7 w-7 py-[10px] px-[10px] bg-[#FDF4F4] text-[#733534] border border-[#F9DADA] rounded-[5px]'>4</p>
                </div>
                <CustomButton size={"large"} className={"!text-[14px] font-[500]"} icon={<GoPlus color='white' size={"2.5vh"}/>} iconPosition={"end"} onClick={handleInviteMember} disabled={!user_permission?.edit_settings}>Invite member</CustomButton>
            </div>

            <div className="mt-4 mb-3">
              {
                isLoading ? (
                  <div className="flex justify-center">
                    <Spin />
                  </div>
                ) : isError ? (
                  <Result
                    status="error"
                    title="Unexpected error occurred"
                    extra={null}
                  />
                )
                :
                (
                  <TeamTable rows={staffs} handleOpen={handleAction}/>
                )
              }
            </div>
        </main>
    </>
  )
}
